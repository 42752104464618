var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EMPTY_CONDITION, } from "@redux/slices/feedFilterRulesSlice";
import { dateOptions, allMatchTypes, textMatchTypes, isValidIsTypeForMatch, staticItemSelectorOptions } from "components/feed/filter-rules/FilterRules.config";
import { isTruthy, keyOf, memberOf } from "shared/helpers/typeGuards";
import { getTypedEntries } from "shared/types/utilityTypes";
import { sortOptions } from "constants/variables";
import { asArray } from "shared/helpers/asArray";
import moment from "moment";
/**
 * URL encodes a feed filter condition to be used in a location search string
 */
function encodeCondition(condition) {
    var match = condition.match, is = condition.is, items = condition.item;
    var itemQuery = (items === null || items === void 0 ? void 0 : items.length) && (match === "map"
        ? __spreadArray(__spreadArray(__spreadArray([], items[0].center, true), [items[0].zoom], false), Object.values(items[0].id || {}), true).join(",")
        : match === "dates" && items[0].id === "custom"
            ? "custom|".concat(encodeURIComponent("".concat(condition.startDate || "")), "|").concat(encodeURIComponent("".concat(condition.endDate || "")))
            : items.map(function (item) { return "".concat(encodeURIComponent(item.id), "|").concat(encodeURIComponent(("name" in item && item.name) || ""), "|").concat(encodeURIComponent(("img" in item && item.img) || "")); }).join("+++")) || null;
    return "".concat(match, "=").concat(is, "+-+").concat(itemQuery);
}
/**
 * URL encodes an individual or array of feed filter rules to be used in a location query string
 */
export function encodeFeedFilterRules(filters) {
    var rules = (Array.isArray(filters[0]) ? filters : [filters]);
    return rules.map(function (rule) {
        return rule.map(encodeCondition).join("&") +
            "&filtered=true";
    }).join("---");
}
var ruleQueryKeys = __spreadArray(__spreadArray([], allMatchTypes, true), ["sort", "layout", "filtered"], false);
/**
 * Converts an encoded feed filter query string into an array of rule objects, preserving the positions of each condition
 *
 * @example
 * const queryString = "brands=is+-+GGtWHQJy|Sensodyne|2f7f6547-ada8-5473-add6-4ec31418dd27&retailers=is+-+eWBHMsg|HEB|13a61340-b8b0-52c5-9bce-79e757ad69fb&brands=is_not+-+kBknoiE|Colgate|a8d59205-490a-5d5c-a257-b044c2df5500&filtered=true";
 *
 * console.log(filterQueryStringToObject(queryString));
 *
 * @example<caption>Output</caption>
 * [
 *   {
 *     brands: [
 *       { index: 0, value: "is+-+GGtWHQJy|Sensodyne|2f7f6547-ada8-5473-add6-4ec31418dd27" },
 *       { index: 2, value: "is_not+-+kBknoiE|Colgate|a8d59205-490a-5d5c-a257-b044c2df5500" }
 *     ],
 *     retailers: [
 *       { index: 1, value: "is+-+eWBHMsg|HEB|13a61340-b8b0-52c5-9bce-79e757ad69fb" }
 *     ],
 *     filtered: [
 *       { index: 3, value: "true" }
 *     ]
 *   }
 * ]
 *
 * @see ruleObjectToQueryString
 * @see encodeFeedFilterRules
 */
export function filterQueryStringToObject(queryString) {
    if (queryString) {
        var searchString = queryString.charAt(0) === "?"
            ? queryString.substring(1)
            : queryString;
        return searchString.split("---").map(function (ruleString) {
            return ruleString.split("&").reduce(function (acc, param, index) {
                var _a = param.split("="), key = _a[0], value = _a[1];
                if (memberOf(ruleQueryKeys).includes(key)) {
                    if (key === "sort" && keyOf(sortOptions).contains(value)) {
                        acc[key] = [{ value: value, index: index }];
                    }
                    else if (key === "layout" && !isNaN(parseInt(value))) {
                        acc[key] = [{
                                value: value,
                                index: index
                            }];
                    }
                    else if (key === "filtered" || memberOf(allMatchTypes).includes(key)) {
                        (acc[key] || (acc[key] = [])).push({
                            value: safeDecodeURIComponent(value),
                            index: index
                        });
                    }
                }
                return acc;
            }, {});
        });
    }
    return [{}];
}
/**
 * Reconstructs an encoded feed filter query string from an individual or array of rule objects
 * produced by {@link filterQueryStringToObject}
 *
 * @example
 * const ruleObject = {
 *   brands: [
 *     { index: 0, value: "is+-+GGtWHQJy|Sensodyne|2f7f6547-ada8-5473-add6-4ec31418dd27" },
 *     { index: 2, value: "is_not+-+kBknoiE|Colgate|a8d59205-490a-5d5c-a257-b044c2df5500" }
 *   ],
 *   retailers: [
 *     { index: 1, value: "is+-+eWBHMsg|HEB|13a61340-b8b0-52c5-9bce-79e757ad69fb" }
 *   ],
 *   filtered: [
 *     { index: 3, value: "true" }
 *   ]
 * }
 * console.log(ruleObjectToQueryString(ruleObject));
 *
 * "brands=is+-+GGtWHQJy|Sensodyne|2f7f6547-ada8-5473-add6-4ec31418dd27&retailers=is+-+eWBHMsg|HEB|13a61340-b8b0-52c5-9bce-79e757ad69fb&brands=is_not+-+kBknoiE|Colgate|a8d59205-490a-5d5c-a257-b044c2df5500&filtered=true";
 *
 * @see encodeFeedFilterRules
 */
export function ruleObjectToQueryString(ruleObject) {
    return asArray(ruleObject).map(function (obj) {
        return Object.entries(obj)
            .filter(function (_a) {
            var _ = _a[0], values = _a[1];
            return values.length;
        })
            .flatMap(function (_a) {
            var key = _a[0], values = _a[1];
            return values.map(function (_a) {
                var index = _a.index, value = _a.value;
                return ({ key: key, value: value, index: index });
            });
        })
            .sort(function (a, b) { return a.index - b.index; })
            .map(function (_a) {
            var key = _a.key, value = _a.value;
            return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(value));
        })
            .join("&");
    }).join("---");
}
function safeDecodeURIComponent(component) {
    try {
        return decodeURIComponent(component);
    }
    catch (e) {
        console.error("Decoding failed for component: ", component, e);
        return component;
    }
}
/**
 * Ridiculous transform that's needed to help TypeScript narrow the Condition union types
 */
function toCondition(_a) {
    var match = _a.match, is = _a.is, item = _a.item;
    return { match: match, is: is, item: item };
}
/**
 * Decodes a URL-encoded feed filter condition into a {@link FilterCondition} object
 */
function decodeCondition(match, conditionString) {
    if (!memberOf(allMatchTypes).includes(match)) {
        return EMPTY_CONDITION;
    }
    var _a = conditionString.split("+-+"), isType = _a[0], itemQuery = _a[1];
    if (match === "map") {
        var parts = itemQuery.split(",");
        if (parts.length === 7 &&
            isValidIsTypeForMatch(isType, match)) {
            return {
                match: match,
                is: isType,
                item: [
                    {
                        center: [parseFloat(parts[0]), parseFloat(parts[1])],
                        zoom: parseInt(parts[2], 10),
                        id: {
                            lat_ne: parseFloat(parts[3]),
                            lng_ne: parseFloat(parts[4]),
                            lat_sw: parseFloat(parts[5]),
                            lng_sw: parseFloat(parts[6]),
                        },
                    },
                ],
            };
        }
    }
    else if (memberOf(textMatchTypes).includes(match)) {
        if (isValidIsTypeForMatch(isType, match)) {
            return {
                match: match,
                is: isType,
                item: [{ id: itemQuery.split("|")[0] }]
            };
        }
    }
    else if (match === "dates") {
        if (isValidIsTypeForMatch(isType, match)) {
            var _b = itemQuery.split("|"), id_1 = _b[0], startDateString = _b[1], endDateString = _b[2];
            var dateOption = dateOptions.map(function (group) { return group.options; }).flat().find(function (option) {
                return option.id === id_1;
            });
            if (dateOption) {
                var startDate = moment(startDateString);
                var endDate = moment(endDateString);
                return __assign({ match: match, is: isType, item: [
                        {
                            id: dateOption.id,
                            name: dateOption.name,
                            noImg: dateOption.noImg
                        }
                    ] }, (id_1 === "custom" && startDate.isValid() && endDate.isValid() && {
                    startDate: startDate.toDate(),
                    endDate: endDate.toDate()
                }));
            }
        }
    }
    else if (isValidIsTypeForMatch(isType, match)) {
        var hasStaticOptions_1 = keyOf(staticItemSelectorOptions).contains(match);
        var items = itemQuery.split("+++")
            .map(function (item) { return item.split("|"); })
            .filter(function (itemParts) { return itemParts.length === 3; })
            .map(function (_a) {
            var _b;
            var id = _a[0], name = _a[1], img = _a[2];
            var itemId = hasStaticOptions_1
                ? (_b = staticItemSelectorOptions[match].find(function (option) { return option.id === id; })) === null || _b === void 0 ? void 0 : _b.id
                : safeDecodeURIComponent(id);
            if (itemId) {
                return {
                    id: itemId,
                    name: safeDecodeURIComponent(name),
                    img: safeDecodeURIComponent(img)
                };
            }
        })
            .filter(isTruthy);
        if (items.length) {
            return toCondition({ match: match, is: isType, item: items });
        }
    }
    return EMPTY_CONDITION;
}
/**
 * Decodes a URL-encoded feed filter query string generated by {@link encodeFeedFilterRules} back
 * into an array of {@link Rule}
 *
 * @see decodeCondition
 */
export function decodeFeedFilterRules(queryString) {
    return filterQueryStringToObject(queryString)
        .filter(function (ruleQuery) { var _a; return (_a = ruleQuery.filtered) === null || _a === void 0 ? void 0 : _a.length; })
        .map(function (ruleQuery) {
        return getTypedEntries(ruleQuery)
            .flatMap(function (_a) {
            var match = _a[0], values = _a[1];
            if (memberOf(allMatchTypes).includes(match) && (values === null || values === void 0 ? void 0 : values.length)) {
                return values.map(function (_a) {
                    var index = _a.index, value = _a.value;
                    return ({
                        condition: decodeCondition(match, value),
                        index: index,
                    });
                });
            }
            return [];
        })
            .sort(function (a, b) { return a.index - b.index; })
            .map(function (_a) {
            var condition = _a.condition;
            return condition;
        })
            .filter(function (condition) { return condition.item; });
    }).filter(function (rule) { return rule.length; });
}
