import { Container } from "constants/styled-components/index";
import { PureComponent } from "react";
import {
  FaFileArchive,
  FaFileExcel,
  FaFilePowerpoint,
  FaFolderOpen,
  FaTrash,
  FaLink,
  FaTimes,
  FaAngleDown
} from "react-icons/fa";
import { FaChartSimple } from "react-icons/fa6";
import { SparkleIcon } from "screens/user/settings/beta-features/SparkleIcon";
import Desktop from "constants/responsive/desktop/index";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import DesktopOrTablet from "constants/responsive/desktop-or-tablet/index";
import {
  awsPhotosPrefixS3,
  appPrefix,
  tooltipDelayLong, validPlanIdsForDelete, TRACKED_FEATURES,
} from "constants/variables/index";
import Mobile from "constants/responsive/mobile/index";
import Loader from "components/loader/index";
import { connect } from "react-redux";
import moment from "moment";
import styled, { withTheme } from "styled-components";
import { excelExport } from "services/download-excel/index";
import { zipTheFeed } from "services/download-zip/index";
import UpgradeIcon from "components/upgrade-icon/index";
import { showModal } from "@redux/slices/modalSlice";
import { logFeatureFound } from "@redux/api/endpoints/users";
import { Modals, PLANOGRAM_ANALYTICS_MODALS } from "components/modal/Modal.constants";
import { selectAnalyticsFeatureGate } from "@redux/selectors/featureGates";


const SelectBottomBar = styled.div`
  position: fixed;
  font-size: 0.8em;
  box-shadow: 0px 0px 20px 20px ${(props) => props.theme.veryLightGrey};
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.veryLightGrey};
  animation: fadeIn 1s;
  width: 100%;
  box-sizing: border-box;
  padding: 2em 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  svg {
    margin-top: 0 !important;
  }
`;
const Action = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  transition: ${(props) => props.theme.fastTransition};
  padding: 0 1em;
  .upgradeIcon {
    padding: 0.5em;
  }
  p {
    color: ${(props) => props.theme.grey2};
  }
  svg {
    transition: ${(props) => props.theme.defaultTransition};
    fill: ${(props) => props.theme.grey2};
    min-width: 1.5em;
    min-height: 1.5em;
    margin-left: 0.5em;
  }
  &:hover {
    background-color: ${(props) => props.theme.grey5} !important;
    p {
      color: ${(props) => props.theme.grey1};
    }
    svg {
      fill: ${(props) => props.theme.grey1};
    }
  }
`;
const Group = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const Actions = styled.div`
  position: absolute;
  animation: slide-from-top-big 0.5s;
  left: 0;
  z-index: -1;
  height: 5em;
  bottom: -5em;
  right: 0;
  font-size: 0.8em;
  display: flex;
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  transition: ${(props) => props.theme.defaultTransition};
  align-items: center;
  background: ${(props) => props.theme.grey6};
  justify-content: space-between;
  .closeContainer {
    padding: 0 2em;
    svg {
      fill: ${(props) => props.theme.midRed};
      margin-left: 0;
    }
  }
  &:hover {
    .closeContainer {
      svg {
        fill: ${(props) => props.theme.deepRed};
      }
    }
  }
`;
const P = styled.p`
  margin: 0;
  cursor: pointer;
  font-weight: bold;
  width: max-content;
  font-size: 1.5em;
  transition: ${(props) => props.theme.defaultTransition};
  padding: 0.5em;
`;

const DropdownButton = styled.div`
  display: flex;
  font-size: 0.8em;
  align-items: center;
  margin: 0.5em 0.25em;
  padding: 0.5em;
  background: transparent;
  transition: ${(props) => props.theme.defaultTransition};
  animation: ${(props) => props.animation && props.active ? props.animation : "none"};
  border-radius: 2em;
  cursor: pointer;

  &.show-hover {
    &:hover {
      background: ${props => props.theme.grey5};
    }
  }

  span {
    font-size: 13px;
    font-weight: bold;
  }

  svg {
    width: 12px;
    fill: ${props => props.theme.grey2};
    padding: 4px
    transition: ${(props) => props.theme.fastTransition};

    &.prepend-icon {
      margin-right: 8px;
    }

    &.append-icon {
      margin-left: 8px;
    }
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: calc(100% + 5px);
  min-width: 220px;
  padding: 0.5em;
  background: ${(props) => props.theme.white};
  border-radius: 12px;
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 0.75em;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9.5px solid ${(props) => props.theme.white};
  }

  @media (max-width: 1024px) {  // Adjust for analytics dropdown being on the bottom
    ${props => props.analytics && `
      top: 60%;
      right: unset;
      left: ${props.timeline ? "52%" : "56%"};

      &:after {
        top: 75px;
        border-bottom: none;
        border-top: 9.5px solid ${props.theme.white};
      }`
    }
  }
`;

const styles = {
  tooltip: {
    height: "100%",
    display: "flex",
  },
  link: {
    color: "white",
    width: "90%",
    fontSize: "1.25em",
  },
  cancel: {
    color: "white",
    width: "30%",
    fontSize: "1.25em",
  },
};

class MultipleSelectMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      zipping: false,
      zipProcessed: 0,
      showAnalytics: false,
    };
    this.showShareMenu = this.showShareMenu.bind(this);
    this.selectShare = this.selectShare.bind(this);
    this.cancelMultipleSelect = this.cancelMultipleSelect.bind(this);
    this.handleEnterpriseGate = this.handleEnterpriseGate.bind(this);
    this.showPowerpointExport = this.showPowerpointExport.bind(this);
    this.toggleAnalytics = this.toggleAnalytics.bind(this);
    this.showAnalyticsModal = this.showAnalyticsModal.bind(this);
    this.selectCollection = this.selectCollection.bind(this);
    this.deletePhotos = this.deletePhotos.bind(this);
    this.selectDownload = this.selectDownload.bind(this);
    this.handleDownloadExcel = this.handleDownloadExcel.bind(this);
  }

  componentDidMount() {
    if (this.props.initialDate) {
      const currentDate = moment(Date.now());
      const selectionDate = moment(this.props.initialDate);
      if (currentDate.diff(selectionDate, "hours") > 20) {
        this.cancelMultipleSelect();
      }
    }
    if (this.props.data) {
      this.handleFeatureFound();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.data && this.props.data) {
      this.handleFeatureFound();
    }
    if (this.props.shareModalIsOpen && prevProps.data !== this.props.data) {
      this.showShareMenu();
    }
  }

  handleFeatureFound() {
    if (!this.props.dataUser?.feature_found_multiple_select) {
      this.props.dispatch(
        logFeatureFound({
          body: { feature_id: TRACKED_FEATURES.MULTIPLE_SELECT },
          componentSuffix: "MULTIPLE_SELECT"
        })
      )
    }
  }

  handleEnterpriseGate() {
    this.props.showModal({ component: Modals.UPGRADE_FLASH })
  }
  handleDownloadExcel() {
    if (this.props.isFlyer) {
      excelExport(
        [
          {
            name: "Exported Circulars",
            data: this.props.data.map((x) => ({
              Retailer: x.retailer,
              Start: x.start_date,
              End: x.end_date,
              Page: x.page_num,
              Link: {
                text: `${appPrefix}f/${x.img}`,
                hyperlink: `${appPrefix}f/${x.img}`,
                tooltip: `${appPrefix}f/${x.img}`,
              },
            })),
          },
        ],
        "Exported Circulars"
      );
    } else {
      excelExport(
        [
          {
            name: "Exported Photos",
            data: this.props.data.map((x) => ({
              "Date Taken": new Date(x.date_taken),
              Retailer: x.retailer,
              Location: `${x.store_num ? `Store #${x.store_num} - ` : ""}${
                x.address
              }`,
              City: x.city,
              State: x.state,
              Country: x.country,
              Link: {
                text: `${appPrefix}s/${x.img}`,
                hyperlink: `${appPrefix}s/${x.img}`,
                tooltip: `${appPrefix}s/${x.img}`,
              },
            })),
          },
        ],
        "Exported Photos"
      );
    }
  }
  selectDownload() {
    this.setState({ zipping: true }, () =>
      this.props.isPlanogram
        ? this.props.data.map((pog) =>
            zipTheFeed(
              pog.photos.map((x) => `${awsPhotosPrefixS3}photos/${x.img}`),
              `${this.props.dataUser.first_name} ${this.props.dataUser.last_name}`,
              pog.photos.map((z) => ({
                ...pog,
              })),
              this.finishedZippedFunc.bind(this),
              "Planograms"
            )
          )
        : zipTheFeed(
            this.props.data.map(
              (x) =>
                `${awsPhotosPrefixS3}${
                  this.props.isFlyer ? "flyers" : "photos"
                }/${x.img}`
            ),
            `${this.props.dataUser.first_name} ${this.props.dataUser.last_name}`,
            this.props.data,
            this.finishedZippedFunc.bind(this),
            this.props.isFlyer ? "Flyers" : "Photos"
          )
    );
  }
  finishedZippedFunc() {
    this.setState({
      zipping: false,
    });
  }
  showPowerpointExport() {
    this.props.showModal({
      component: Modals.POWERPOINT_EXPORT,
      data: {
        photos: this.props.data,
        isFlyer: this.props.isFlyer,
        isPlanogram: this.props.isPlanogram,
        presentationName: this.props.isPlanogram
          ? "Planogram Report"
          : this.props.isFlyer
          ? "Flyers Report"
          : "Photos Report",
      },
    });
  }
  cancelMultipleSelect() {
    this.props.setData({
      type: `RESET_SELECT_MULTIPLE_${
        this.props.isPlanogram
          ? "PLANOGRAMS"
          : this.props.isFlyer
          ? "FLYERS"
          : "PHOTOS"
      }`,
    });
  }
  selectShare() {
    this.props.showModal({
      component: Modals.SHARE,
      data: {
        isPlanogram: this.props.isPlanogram,
        isFlyer: this.props.isFlyer,
        data: this.props.data,
      },
    });
  }
  selectCollection() {
    this.props.showModal({
      component: Modals.ADD_TO_COLLECTION,
      data: { photos: this.props.data },
    });
  }
  showShareMenu() {
    this.props.showModal({
      component: Modals.FEED_SHARE_MENU,
      data: {
        isFlyer: this.props.isFlyer,
        isPlanogram: this.props.isPlanogram,
        data: this.props.data,
      },
    });
  }
  deletePhotos() {
    this.props.showModal({
      component: Modals.DELETE,
      data: { photos: this.props.data }
    });
  }

  toggleAnalytics() {
    this.setState(prev => ({
      showAnalytics: !prev.showAnalytics,
    }));
  }

  showAnalyticsModal(analyticsModalComponent) {
    const photos = this.props.data.flatMap(item => item.photos.map(photo => photo.img));

    this.props.showModal({
      component: Modals.PLANOGRAM_ANALYTICS,
      data: {
        component: analyticsModalComponent,
        data: { photos }
      }
    })
  }

  render() {
    const descriptor =
      this.props.data && this.props.data.length > 1
        ? this.props.isPlanogram
          ? "planograms"
          : this.props.isFlyer
          ? "circulars"
          : "photos"
        : this.props.isPlanogram
        ? "planogram"
        : this.props.isFlyer
        ? "circular"
        : "photo";
    const isFreePlan = this.props.dataUser && this.props.dataUser.plan_id === 1;
    const showDelete = (
      !this.props.isFlyer &&
      !this.props.isPlanogram &&
      (
        validPlanIdsForDelete.includes(this.props.dataUser.plan_id) ||
        this.props.data.every(photo => photo.uploader === this.props.dataUser.id)
      )
    );
    const { showAnalytics } = this.state;

    if (
      this.props.showFeedNavMenu ||
      this.props.showPlanogramMoreMenu ||
      this.props.showFeedCompanyTypeMenu ||
      this.props.showFeedMoreMenu ||
      this.props.showFeedShareMenu ||
      this.props.showFlyerFilterMenu ||
      this.props.showFeedTypeMenu
    ) {
      return null;
    }
    return (
      <>
        <DesktopOrTablet
          content={
            <Actions primaryColor={this.props.primaryColor}>
              <Group>
                <Action onClick={this.showShareMenu}>
                  <P>
                    {`${this.props.data.length} ${descriptor} ${
                      this.props.translations["66"] || "selected"
                    }`}
                  </P>
                </Action>
              </Group>

              <Group>
                {this.props.analyticsFeatureGate.is_enabled &&
                  <>
                    <Action onClick={this.toggleAnalytics}>
                      <DropdownButton
                        tabIndex={0}
                        selected={showAnalytics}
                      >
                        <FaChartSimple size="1.25em" className='prepend-icon' />
                        <span>Analytics</span>
                        {showAnalytics ? (
                          <FaTimes size="1.15em" className="FaTimes append-icon" />
                        ) : (
                          <FaAngleDown size="1.25em" className="FaAngleDown append-icon"/>
                        )}
                      </DropdownButton>

                      {showAnalytics && (
                        <DropdownMenu analytics timeline={this.props.timeline}>
                          {Object.values(PLANOGRAM_ANALYTICS_MODALS).map(({ title, component, isBeta, icon: Icon }) => {
                            return (!isBeta || !!this.props.dataUser?.show_beta_features) && (
                              <DropdownButton className='show-hover'
                                tabIndex={0}
                                key={component}
                                onClick={() => this.showAnalyticsModal(component)}
                              >
                                <Icon className='prepend-icon'/>
                                <span>{title}</span>
                                {isBeta && (
                                  <SparkleIcon className='append-icon'
                                    noPadding
                                    width="1em"
                                    margin="0 -0.25em 0.1em 0"
                                  />
                                )}
                              </DropdownButton>
                            )
                          })}
                        </DropdownMenu>
                      )}
                    </Action>
                  </>
                }

                <Action onClick={this.selectShare}>
                  <FaLink />
                  <P>Share a link</P>
                </Action>

                <Action
                  onClick={
                    this.state.zipping
                      ? null
                      : isFreePlan
                      ? this.handleEnterpriseGate
                      : this.selectDownload
                  }
                >
                  {isFreePlan ? (
                    <UpgradeIcon />
                  ) : this.state.zipping && this.props.data.length > 3 ? (
                    <Loader
                      width="auto"
                      size="1em"
                      svgMargin="0"
                      padding="0"
                      color={this.props.theme.white}
                    />
                  ) : (
                    <FaFileArchive />
                  )}
                  <Desktop
                    content={
                      <P>
                        {this.state.zipping && this.props.data.length > 3
                          ? this.state.zipProcessed > 0
                            ? `Zipping ${this.state.zipProcessed} of ${this.props.data.length}`
                            : "Zipping..."
                          : "Export to ZIP"}
                      </P>
                    }
                  />
                </Action>

                <Action
                  onClick={
                    isFreePlan ? this.handleEnterpriseGate : this.showPowerpointExport
                  }
                >
                  {isFreePlan ? <UpgradeIcon /> : <FaFilePowerpoint />}
                  <Desktop content={<P>Export to PowerPoint</P>} />
                </Action>

                {this.props.isPlanogram ? null : (
                  <Action
                    onClick={
                      isFreePlan
                        ? this.handleEnterpriseGate
                        : this.handleDownloadExcel
                    }
                  >
                    {isFreePlan ? <UpgradeIcon /> : <FaFileExcel />}
                    <Desktop content={<P>Export to Excel</P>} />
                  </Action>
                )}

                {this.props.isFlyer || this.props.isPlanogram ? null : (
                  <Action onClick={this.selectCollection}>
                    <FaFolderOpen />
                    <Desktop content={<P>Add to collection</P>} />
                  </Action>
                )}

                {showDelete && (
                  <Action onClick={this.deletePhotos}>
                    <FaTrash/>
                    <Desktop content={<P>Delete</P>}/>
                  </Action>
                )}

                <Tooltip
                  unmountHTMLWhenHide
                  html={
                    <TooltipText maxWidth="800px">Clear selection</TooltipText>
                  }
                  position="bottom"
                  delay={tooltipDelayLong}
                  trigger="mouseenter"
                  style={styles.tooltip}
                >
                  <Action
                    className="closeContainer"
                    onClick={this.cancelMultipleSelect}
                  >
                    <FaTimes />
                  </Action>
                </Tooltip>
              </Group>
            </Actions>
          }
        />

        <Mobile
          content={
            <SelectBottomBar>
              <Container
                onClick={this.selectShare}
                style={{ ...styles.link }}
                primaryColor={this.props.theme.deepBlue}
              >
                <FaLink />
                {`Share a link to ${this.props.data.length} ${descriptor}`}
              </Container>
              <Container
                style={{ ...styles.cancel }}
                primaryColor={this.props.theme.deepRed}
                onClick={this.cancelMultipleSelect}
              >
                Cancel
              </Container>
            </SelectBottomBar>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  dataLandingPageAbout: state.dataLandingPageAbout,
  translations: state.translations,
  showFeedNavMenu: state.showFeedNavMenu,
  showPlanogramMoreMenu: state.showPlanogramMoreMenu,
  showFeedCompanyTypeMenu: state.showFeedCompanyTypeMenu,
  showFeedMoreMenu: state.showFeedMoreMenu,
  showFeedShareMenu: state.showFeedShareMenu,
  showFlyerFilterMenu: state.showFlyerFilterMenu,
  showFeedTypeMenu: state.showFeedTypeMenu,
  shareModalIsOpen: state.modal?.component === Modals.FEED_SHARE_MENU,
  analyticsFeatureGate: selectAnalyticsFeatureGate(state),
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
  showModal: (payload) => dispatch(showModal(payload)),
  dispatch
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(MultipleSelectMenu)
);
